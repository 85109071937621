<template>
  <main>
    <package-header title="Edit Application" subTitle="12764 Smith"></package-header>

    <div class="container-fluid">
      <div class="row">
        <div class="col">

          <div class="mb-3 border-bottom pb-3">
            <!-- Back to applications link -->
            <router-link class="mr-2" to="/pages/applications">
              <svg class="icon icon-arrow_cta_back mr-sm-1"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_cta_back"></use></svg>
              <span class="d-none d-sm-inline-block">Return to Applications</span>
              <span class="d-sm-none">Back</span>
            </router-link>

            <!-- Change instrument dropdown -->
            <a href="javascript:void(0)" class="btn btn-md btn-secondary dropdown-toggle d-sm-inline-block" id="instrument" data-toggle="dropdown" aria-controls="instrument-contents" aria-expanded="false">
              Go To Application
              <span class="caret"></span>
            </a>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" href="javascript:void(0)">01 - Transfer Caso <span class="badge badge-success">E-signed</span></a>
              <a class="dropdown-item font-weight-bold" href="javascript:void(0)">02 - A1 release</a>
              <a class="dropdown-item" href="javascript:void(0)">03 - Mortgage Caso</a>
              <a class="dropdown-item" href="javascript:void(0)">04 - A2 release</a>
              <a class="dropdown-item" href="javascript:void(0)">05 - Application with longer name</a>
            </div>
          </div>

          <!-- Package title and actions -->
          <div class="d-md-flex align-items-start justify-content-between">
            <div>
              <h2 class="page-title mr-2">A1 release</h2>

              <div class="d-flex align-items-center">
                <small>
                  <span class="text-muted text-small">Application Type: </span>
                  <strong>Application Type</strong>
                </small>
                <button class="btn btn-secondary btn-sm ml-2">Change</button>
              </div>

              <ul class="list-inline mb-1">
                <li class="list-inline-item">
                  <small><span class="text-muted text-small">Status:</span> <strong>Drafted</strong></small>
                </li>
                <li class="list-inline-item">
                  <small><span class="text-muted">Modified:</span> <strong>Sep 22, 2018 at 5:33 PM</strong></small>
                </li>
              </ul>
            </div>

            <!-- Drawer toggle and delete package -->
            <div class="d-flex justify-content-between">
              <button class="btn btn-md btn-secondary d-md-none mb-2" type="button" data-toggle="collapse" data-target="#application-nav" aria-expanded="false" aria-controls="application-nav">
                <svg class="icon icon-more"><use xlink:href="/icons/symbol-defs.svg#icon-more"></use></svg>
                <svg class="icon icon-close"><use xlink:href="/icons/symbol-defs.svg#icon-close"></use></svg>
                Navigate Application
              </button>
              <button type="button" class="btn btn-md btn-danger mb-2">
                <svg class="icon icon-delete d-sm-none"><use xlink:href="/icons/symbol-defs.svg#icon-delete"></use></svg>
                <span class="d-none d-sm-block">Delete Application</span>
              </button>
            </div>
          </div>

          <!-- Main package content -->
          <div class="row mt-md-3">

            <!-- Step wizard -->
            <div class="col-md-3 mb-2 mb-md-4">
              <div class="collapse collapse-md" id="application-nav">
                <div class="step-wizard">
                  <div class="step completed">
                    <router-link class="step-link" to="/pages/edit-application/applicant">Applicant</router-link>
                  </div>
                  <div class="step completed">
                    <router-link class="step-link" to="/pages/edit-application/pid-legal-description">Description of Land</router-link>
                  </div>
                  <div class="step completed">
                    <router-link class="step-link" to="/pages/edit-application/transferor">Transferor</router-link>
                  </div>
                  <div class="step completed">
                    <a class="step-link" href="javascript:void(0)">Transferee</a>
                  </div>
                  <div class="step active">
                    <router-link class="step-link" to="/pages/edit-application/transfer-details">Transfer Details</router-link>
                  </div>
                  <div class="step">
                    <router-link class="step-link" to="/pages/edit-application/execution">Execution</router-link>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Informational Document</a>
                  </div>
                  <div class="step">
                    <router-link class="step-link" to="/pages/electronic-instrument">Electronic Instrument</router-link>
                  </div>
                </div>
              </div>
            </div>

            <!-- Data entry form -->
            <div class="col-md-9 col-lg-7">

              <h2 class="mb-3">Transfer Details</h2>

              <div class="mb-4 p-2 p-md-4 bg-medium">

                <!-- Application form -->
                <form action="">
                  <div class="row">
                    <div class="col-md-10">
                      <div class="">
                        <div class="form-group">
                          <label for="estate">Freehold estate transferred</label>
                          <select class="form-control" name="estate" id="estate" v-model="estate">
                            <option value="estate1">Fee Simple</option>
                            <option value="estate2">Fee Simple on Condition</option>
                            <option value="estate3">Fee Simple with Exceptions and Reservations</option>
                            <option value="estate4">Determinable Fee Simple</option>
                            <option value="estate5">For the Life of the Transferee</option>
                          </select>
                        </div>

                        <div v-if="(estate === 'estate2' || estate === 'estate3' || estate === 'estate4') && estateSaved === false">
                          <div class="form-group">
                            <label for="information">
                              Information (optional)
                              <a class="ml-1" href="#" data-toggle="popover" data-placement="top" data-content="Tooltip content" v-popover><svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg></a>
                            </label>
                            <textarea class="form-control" name="information" id="information" cols="30" rows="4" v-model="information"></textarea>
                          </div>
                          <div class="form-actions mt-1 mb-4">
                            <button class="btn btn-md btn-tertiary" @click.prevent="estateSaved = true">Save</button>
                            <button class="btn btn-md btn-link" @click.prevent="estateSaved = false">Cancel</button>
                          </div>
                        </div>

                        <div class="mb-3" v-if="estateSaved === true">
                          <strong>Information</strong>
                          <p class="mb-0">{{ information }}</p>
                          <a href="#" @click.prevent="estateSaved = false">Edit</a>
                        </div>

                        <div class="form-group">
                          <label for="marketValue">Market Value</label>
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text">$</span>
                            </div>
                            <input class="form-control col-sm-6 border-left-0" type="text" name="marketValue">
                          </div>
                        </div>

                        <div class="form-group">
                          <label for="consideration">Consideration</label>
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text">$</span>
                            </div>
                            <input class="form-control col-sm-6 border-left-0" type="text" name="consideration">
                          </div>
                        </div>

                        <div class="form-group">
                          <label for="otherConsiderations">
                            Other Considerations
                            <a class="ml-1" href="#" data-toggle="popover" data-placement="top" data-content="Tooltip content" v-popover><svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg></a>
                          </label>
                          <textarea class="form-control" name="otherConsiderations" id="otherConsiderations" cols="30" rows="4"></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <!-- Action buttons -->
              <div class="d-flex flex-column-reverse flex-sm-row justify-content-sm-between d-print-none">
                <div class="mt-2 mt-sm-0">
                  <button class="btn btn-secondary">Back</button>
                </div>
                <div class="d-flex justify-content-between">
                  <button class="btn btn-secondary mr-1">Save Progress</button>
                  <button class="btn btn-primary">Continue <svg class="icon icon-arrow_cta ml-1"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_cta"></use></svg></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Scroll to top -->
      <a href="#top" class="d-block float-right scroll-to-top">
        <svg class="icon icon-arrow_upward"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_upward"></use></svg>Back to Top
      </a>
    </div>

    <help-panel title="Applications"></help-panel>
  </main>
</template>

<script>

import PackageHeader from '../../../components/PackageHeader.vue'
import PackageNavigation from '../../../components/PackageNavigation.vue'
import HelpPanel from '../../../components/HelpPanel.vue'

export default {

  components: {
    'package-header': PackageHeader,
    'package-navigation': PackageNavigation,
    'help-panel': HelpPanel
  },

  data () {
    return {
      estateSaved: false,
      estate: 'estate1',
      information: ''
    }
  },
  methods: {

  }
}
</script>

